<!-- 销售云税种设置 -->
<template>
  <div class="top">
    <div class="taxHead">
      <!-- 税率 -->
      <div class="taxTitle">{{ $t("c50") }}</div>
      <!-- 新税 -->
      <el-button
        class="taxBt"
        icon="el-icon-plus"
        size="medium"
        type="primary"
        style="margin-top: 10px"
        @click="createTax"
        >{{ $t("c51") }}</el-button
      >
    </div>
    <div class="table">
      <el-table :data="tableData">
        <!-- 税名称 -->
        <el-table-column prop="name" :label="$t('c52')"> </el-table-column>
        <!-- 比率 -->
        <el-table-column prop="ratio" :label="$t('c53')"> </el-table-column>
        <!-- 操作   -->
        <el-table-column
          prop=""
          :label="$t('label.department.operation')"
          width="120px"
        >
          <template slot-scope="scope">
            <div class="editBox">
              <!-- 编辑 -->
              <span
                @click="editTax(scope.row)"
                style="color: #107dcb; cursor: pointer"
              >
                {{ $t("pagecreator.page.button.edit") }}
              </span>
              <!-- 删除 -->
              <span
                @click="deleteTax(scope.row)"
                style="color: #107dcb; cursor: pointer"
              >
                {{ $t("component.chatter.button.delete") }}
              </span>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-form
      :status-icon="false"
      ref="fromFormat"
      :model="templateData"
      class="clearfix"
      :validate-on-rule-change="false"
      label-position="left"
      :rules="rules"
    >
      <el-dialog
        :title="$t('c51')"
        ref="priceItem"
        :visible.sync="taxVisible"
        width="600px"
        top="300px"
        :close-on-click-modal="true"
        :before-close="handleClose"
        :lock-scroll="true"
      >
        <div class="mainContent">
          <!-- 税名称 -->
          <el-form-item
            :label="$t('c52')"
            label-width="30%"
            style="min-height: 38px; width: 100%"
            prop="taxName"
          >
            <el-input
              v-model="templateData.taxName"
              style="width: 80%"
              size="mini"
            ></el-input>
          </el-form-item>
          <!-- 比率 -->
          <el-form-item
            :label="$t('c53')"
            label-width="30%"
            style="min-height: 38px; width: 100%"
            prop="taxValue"
          >
            <el-input-number
              v-model="templateData.taxValue"
              type="number"
              style="width: 80%"
              size="mini"
              :min="0"
              :controls='false'
            ></el-input-number>
          </el-form-item>
        </div>

        <div slot="footer" class="dialog-footer" style="text-align: right">
          <el-form-item class="btBox">
            <el-button size="mini" class="whiteBt" @click="handleClose">
              {{ $t("button_partner_cancel") }}
            </el-button>
            <el-button size="mini" type="primary" class="blueBt" @click="save">
              {{ $t("label.emailtocloudcc.button.sure") }}
            </el-button>
          </el-form-item>
        </div>
      </el-dialog>
    </el-form>
  </div>
</template>

<script>
import * as CommonObjApi from "./api";

export default {
  components: {},
  data() {
    return {
      tableData: [],
      taxVisible: false,
      rules: {
        taxName: [
          { required: true, message: this.$i18n.t("c54"), trigger: "blur" },
        ], //请填写税种类
        taxValue: [
          { required: true, message: this.$i18n.t("c55"), trigger: "blur" },
          { pattern:/^100{2}$|^(\d|[1-9]\d)(\.\d+)*$/,message: this.$i18n.t('c517'),trigger: 'blur'}
        ], //请填写税值
      },
      templateData: {
        taxName: "",
        taxValue: "",
        id: "",
      },
    };
  },
  //方法集合
  methods: {
    createTax() {
      this.taxVisible = true;
      this.templateData.taxName = "";
      this.templateData.taxValue = "";
      this.templateData.id = "";
    },
    editTax(row) {
      this.taxVisible = true;
      this.templateData.taxName = row.name;
      this.templateData.taxValue = row.ratio;
      this.templateData.id = row.id;
    },
    deleteTax(row) {
      CommonObjApi.deleteTax({ id: row.id }).then((res) => {
        if (res.result) {
          this.init();
        } else {
          this.$message({
            showClose: true,
            type: "error",
            message: res.returnInfo,
          });
        }
      });
    },
    save() {
      this.$refs.fromFormat.validate((valid) => {
        if (valid) {
          this.taxVisible = false;
          CommonObjApi.taxSave({
            name: this.templateData.taxName,
            ratio: this.templateData.taxValue,
            id: this.templateData.id,
          }).then((res) => {
            if (res.result) {
              this.init();
              this.templateData = {
                taxName: "",
                taxValue: "",
              };
            } else {
              this.$message({
                showClose: true,
                type: "error",
                message: res.returnInfo,
              });
            }
          });
        } else {
          return false;
        }
      });
    },
    init() {
      CommonObjApi.ListTax({ id: "" }).then((res) => {
        if (res.result) {
          this.tableData = res.data;
          this.tableData.reverse();
        } else {
          this.$message({
            showClose: true,
            type: "error",
            message: res.returnInfo,
          });
        }
      });
    },
    handleClose() {
      this.taxVisible = false;
    },
  },
  created() {
    this.init();
  },
};
</script>
<style lang='scss' scoped>
.editBox {
  display: flex;
  justify-content: space-around;
}
.top {
  padding: 10px;
  .taxHead {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    .taxTitle {
      font-size: 16px;
      font-family: SourceHanSansCN-Bold, SourceHanSansCN;
      font-weight: bold;
      color: #080707;
      line-height: 24px;
    }
  }
  ::v-deep .is-leaf {
    color: #333;
    border-bottom: 1px solid #dedcda;
    border-right: 1px solid #dedcda;
  }
  ::v-deep .el-dialog__body {
    overflow: auto;
    padding: 12px 68px;
    font-size: 12px !important;
    word-break: normal !important;
  }
  ::v-deep .el-dialog__footer {
    padding: 0px 22px;
    border-top: 1px solid #dcdcdc;
  }
  ::v-deep .el-table {
    background: #ffffff;
    border: 1px solid #dedcda;
  }

  ::v-deep .el-dialog__header {
    text-align: left;
  }

  ::v-deep .el-table-column--selection .cell {
    text-align: center;
  }

  // 表头样式
  .el-table--border,
  .el-table--group {
    border: 1px solid #dedcda;
  }

  ::v-deep .el-table th.is-leaf {
    color: #333;
    border-bottom: 1px solid #dedcda;
  }

  ::v-deep .el-table--border th {
    border-right: 1px solid #dedcda;
  }

  ::v-deep .el-table__header tr th {
    padding: 8px;
    padding-left: 0;
    padding-right: 0;
  }

  ::v-deep .el-table td:first-child .cell {
    padding-right: 14px;
  }

  // 表头颜色自定义、单元格超出...显示
  ::v-deep .el-table__header {
    img {
      margin-top: -4px;
    }

    tr th {
      background: #fafaf9;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    th .cell {
      padding-right: 14px;

      .el-checkbox {
        margin-left: 0;
      }
    }
  }

  ::v-deep .el-table th .cell {
    min-height: 23px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    // padding-right: 20px;
  }

  ::v-deep .el-table td {
    position: relative;
    padding: 9px 0;
    color: #080707;
    border-bottom: 1px solid #dedcda;
    word-break: break-all;

    .cell {
      min-height: 23px;
      position: relative;

      > span,
      > a {
        width: calc(100% - 1px);
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        > span > img {
          right: 5px !important;
        }
      }
    }
  }

  ::v-deep .el-table--border td {
    border-right: none;
  }

  ::v-deep .is-checked {
    .el-checkbox__input {
      .el-checkbox__inner {
        background-color: #006dcc !important;
        border-color: #006dcc !important;
      }
    }
  }
  ::v-deep .el-input-number .el-input__inner{
    text-align: left;
  }
}
</style>