import axios from '@/config/httpConfig'

// 删除税
export function deleteTax(data) {
  return axios.post('/tax/deleteTax', data)
}

// 主从录入保存税种
export function taxSave(data) {
  return axios.post('/tax/save', data)
}

// 主从录入获取税种信息
export function ListTax(data) {
  return axios.post('/tax/listTax', data)
}
